<template>
  <div>
<el-row class="bgW">
      <el-col :span="21">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="店铺名称" v-if="!userInfo.shop_id">
            <el-select
              v-model="search.shop_id"
              clearable
              remote
              filterable
              :remote-method="remoteShop"
              placeholder="请输入店铺名称"
              class="w200"
            >
              <el-option
                v-for="item in options.shop_id"
                :key="item.shopId"
                :label="item.shopName"
                :value="item.shopId"
              />
            </el-select>
          </el-form-item>
           <el-form-item label="日期">
            <el-date-picker
              v-model="search.start_time"
              type="date"
              class="w130"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
            <el-date-picker
              v-model="search.end_time"
              type="date"
              class="w130"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="3" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        <el-button type="primary"  size="mini" :loading="exportS" @click="exportExcel">导出数据</el-button>
      </el-col>
    </el-row>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
          <ElTableColumn label="门店" prop="shop_name" />
            <ElTableColumn label="产品名称" prop="goods_name" />
            <ElTableColumn label="数量" prop="number" />
            <ElTableColumn label="单价(元)" prop="pay_price" />
            <ElTableColumn label="金额(元)" prop="sum_price" />
            <ElTableColumn label="顾客姓名" prop="consignee" />
          </el-table>
          <div class="reveal">
            <div class="revealRow">
              <div>优惠合计：</div>
              <div class="blod">￥{{discount_price}}</div>
            </div>
            <div class="revealRow">
              <div>销售合计：</div>
              <div class="blod">￥{{total_price}}</div>
            </div>
            <div class="revealRow">
              <div>当日应收：</div>
              <div class="blod">￥{{receivable_price}}</div>
            </div>
          </div>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { handleExportExcel } from "@/vendor/handleExportExcel";
import { getShopList } from "@/api/shop";
import pagination from '@/components/Pagination'
import { dayDetails } from "@/api/statistics";
class Search {
  start_time = "";
  end_time = "";
  shop_id = "";
}
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Options {
  shop_id = [];
}
export default {
  name: 'Finance',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      page: new Page(), // 分页
      options: new Options(),
      list: [], // 数据展示
      idList:[],
      discount_price:'',
      total_price:'',
      receivable_price:'',
      exportS: false,
    }
  },
computed: {
  ...mapGetters(["userInfo"])
},
mounted () {
  this.getList();
},
  methods: {
    async getList(option) {
      if (option === "search") this.page = new Page();
      try {
        const res = await dayDetails({
          ...this.search, ...this.page
        });
         this.list = res.data.list;
        this.page.total = res.data.dataCount;
        this.discount_price = res.data.discount_price;
        this.total_price = res.data.total_price;
        this.receivable_price = res.data.receivable_price;
      } catch (e) {
        this.list = [];
        this.page.total = 0;
      }

    },
     remoteShop(e) {
      getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
        this.options.shop_id = res.list;
      });
    },
     // 导出表格
    async exportExcel() {
      this.exportS = true
      const { data } = await dayDetails({
         ...this.search,no_page:1
      });
      handleExportExcel(
        [
          '产品名称',
          '数量',
          '单价(元)',
          '金额(元)',
          '门店',
          '顾客姓名'
        ],
        [
          'goods_name',
          'number',
          'pay_price',
          'sum_price',
          'shop_name',
          'consignee'
        ],
        data.list,
        '财务报表'
      );
      this.exportS = false
    },
  }
}
</script>

<style lang="scss" scoped>
.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.reveal{
  display: flex;
  justify-content: flex-end;
  .revealRow{
    display: flex;
    padding: 15px;
    .blod{
      text-align: right;
      font-weight: bold;
      color: rgb(187, 27, 35);
    }
  }

}
</style>
